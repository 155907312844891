<template>
  <div class="content">
    <TitleInfo title1="客户管理" title2="缴费记录"></TitleInfo>
    <div class="main">
      <div class="title flexCB">
        <div class="key align-center">
          <div style="margin-left: 10px">缴费时间：</div>
          <el-date-picker
            v-model="dateRang"
            type="datetimerange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :shortcuts="shortcuts"
            format="YYYY-MM-DD HH:mm:ss"
            :clearable="false"
            style="width: 350px"
          >
          </el-date-picker>
          <el-button
            style="margin-left: 10px"
            :icon="Search"
            color="#11488B"
            :round="false"
            @click="initGetList"
            >搜索</el-button
          >
          <el-button
            :icon="Refresh"
            color="#67C23A"
            :round="false"
            @click="resetInfo"
            >重置</el-button
          >
        </div>
      </div>
      <div class="tableInfo">
        <el-table
          :data="tableData"
          border
          class="tableInfo"
          style="width: 100%; height: 100%"
        >
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column
            prop="Operator"
            label="缴费人"
            width="400"
          ></el-table-column>
          <el-table-column
            prop="PayTypeLabel"
            label="缴费途径"
          ></el-table-column>
          <el-table-column prop="PayNum" label="缴费金额"></el-table-column>
          <el-table-column prop="PayPurpose" label="缴费用途"></el-table-column>
          <el-table-column
            prop="CreateTime"
            label="操作时间"
            width="400"
          ></el-table-column>
        </el-table>
      </div>
      <div class="bottom align-center">
        <el-pagination
          v-model:current-page="queryForm.pageIndex"
          v-model:page-size="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          popper-class="dropdownPopper"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import { Search, Refresh, Edit, Delete } from '@element-plus/icons-vue'
import { isNull } from '@/utitls/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import TitleInfo from '@/components/TitleInfo'
import { GetPayRecordPageList } from '@/api/WX'
import { GetUserFillList } from '@/api/User'
import moment from 'moment'
const queryForm = ref({
  userID: '',
  dtStart: new Date(),
  dtEnd: new Date().getTime() - 3600 * 1000 * 24 * 7,
  pageIndex: 1,
  pageSize: 10
})
const userInfo = ref([
  {
    ID: '',
    Name: '全部'
  }
])
//起止日期
const shortcuts = ref([
  {
    text: '最近一周',
    value: () => {
      return [moment().subtract(7, 'days').calendar(), moment().endOf('day')]
    }
  },
  {
    text: '最近一个月',
    value: () => {
      return [moment().subtract(1, 'months').calendar(), moment().endOf('day')]
    }
  },
  {
    text: '最近三个月',
    value: () => {
      return [moment().subtract(3, 'months').calendar(), moment().endOf('day')]
    }
  }
])
const dateRang = ref([moment().startOf('day'), moment().endOf('day')])
const total = ref(0)
const tableData = reactive([])
onMounted(() => {
  // GetUserFillList()
  //   .then((res) => {
  //     if (res) {
  //       res.Data.forEach((item) => {
  //         userInfo.value.push({
  //           ID: item.ID,
  //           Name: item.Name
  //         })
  //       })
  //     }
  //   })
  //   .catch((err) => {
  //     ElMessage({
  //       showClose: true,
  //       message: `${err}`,
  //       type: 'error'
  //     })
  //   })
  initGetList()
})
const initGetList = async () => {
  queryForm.value.dtStart = moment(dateRang.value[0]).format(
    'yyyy-MM-D HH:mm:ss'
  )
  queryForm.value.dtEnd = moment(dateRang.value[1]).format('yyyy-MM-D HH:mm:ss')

  GetPayRecordPageList(queryForm.value)
    .then((res) => {
      if (res) {
        total.value = res.Total
        tableData.length = 0
        res.Data.forEach((element) => {
          tableData.push(element)
        })
      } else {
        total.value = 0
        tableData.length = 0
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
      console.log(err)
      total.value = 0
      tableData.length = 0
    })
}
const handleSizeChange = (pageSize) => {
  queryForm.value.pageIndex = 1
  initGetList()
}
const handleCurrentChange = (pageNum) => {
  initGetList()
}
const resetInfo = () => {
  queryForm.value = {
    userID: '',
    dtStart: new Date(),
    dtEnd: new Date().getTime() - 3600 * 1000 * 24 * 7,
    pageIndex: 1,
    pageSize: 10
  }
  initGetList()
}
</script>
<style lang="scss" scoped src="@/styles/elementReset.scss"></style>
<style lang="scss" scoped src="./assets/index.scss"></style>
